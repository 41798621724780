module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"deb-braun-memorial","short_name":"deb","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon_db.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1c9eed4bf075fa71a77ee59d96c53494"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
